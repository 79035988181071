import React, { useEffect, useContext } from 'react'
import {
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { API } from 'aws-amplify'
import { Box } from '@mui/system'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import Hider from '../utils/Hider'

export default function CreditCardList({}) {
  const { gStripe, gCreditCard } = useContext(GlobalStateContext)

  useEffect(() => {
    if (gStripe.customer.id) {
      gCreditCard.getCreditCard(gStripe.customer.id) // 拿到卡片資訊
    }
  }, [gStripe.customer.id])

  const changeDefaultCard = (id) => {
    API.post('Attendance', `/payment_method`, {
      body: {
        customerId: gStripe.customer.id,
        defaultPaymentMethod: id,
        subscription: gStripe.subscription.id,
      },
    })
      .then()
      .catch((err) => console.log(err))
  }

  return (
    <Box>
      <List disablePadding>
        {/* {JSON.stringify(gStripe.subscription.id)} */}
        {gCreditCard.creditCardList.length > 0 &&
          gCreditCard.creditCardList.map((row, index) => (
            <ListItemButton
              key={row.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '350px',
              }}
              onClick={(event) => {
                event.preventDefault()
                gStripe.setCustomer({
                  ...gStripe.customer,
                  invoice_settings: { default_payment_method: row.id },
                })
                changeDefaultCard(row.id)
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ListItemText
                  sx={{
                    opacity:
                      row.id ===
                      gStripe.customer.invoice_settings.default_payment_method
                        ? '1'
                        : '.5',
                  }}
                >
                  {row.brand} - {row.last4}
                </ListItemText>
                <ListItemText
                  disableTypography
                  primary={
                    <Hider
                      show={
                        row.id ===
                        gStripe.customer.invoice_settings.default_payment_method
                      }
                    >
                      <Typography
                        style={{
                          color: '#FFFFFF',
                          backgroundColor: '#68C6C8',
                          fontSize: '12px',
                          borderRadius: '3px',
                          padding: '2px',
                          marginLeft: '5px',
                        }}
                      >
                        デフォルト
                      </Typography>
                    </Hider>
                  }
                />
              </Box>
              <Hider show={gCreditCard.creditCardList.length > 1}>
                <ListItemIcon
                  onClick={(event) => {
                    event.stopPropagation()
                    //刪掉卡片
                    let tmp = [...gCreditCard.creditCardList]
                    tmp.splice(index, 1)
                    gCreditCard.setCreditCardList(tmp)
                    if (
                      row.id ===
                      gStripe.customer.invoice_settings.default_payment_method
                    ) {
                      gStripe.setCustomer({
                        ...gStripe.customer,
                        invoice_settings: {
                          default_payment_method: tmp[0].id,
                        },
                      })
                      changeDefaultCard(tmp[0].id)
                    }
                    API.del('Attendance', `/credit_card`, {
                      body: { paymentId: row.id },
                    })
                      .then(() => console.log('success'))
                      .catch((err) => console.log(err))
                  }}
                >
                  <DeleteOutlineIcon
                    sx={{
                      color: 'black',
                      opacity:
                        row.id ===
                        gStripe.customer.invoice_settings.default_payment_method
                          ? '1'
                          : '.5',
                      '&:hover': {
                        color: '#CC4A44',
                        opacity: 1,
                      },
                    }}
                  />
                </ListItemIcon>
              </Hider>
            </ListItemButton>
          ))}
      </List>
    </Box>
  )
}
