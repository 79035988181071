import React, { useContext } from 'react'
import { Box, Typography, Button, CircularProgress } from '@mui/material'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import Hider from '../utils/Hider'

export default function DowngradePlan({
  planToBe,
  changePlanPending,
  setOpenChangePlan,
  downgrade,
  cancelDowngrade,
}) {
  const { gStripe } = useContext(GlobalStateContext)

  return (
    <Box>
      <Hider show={changePlanPending}>
        <CircularProgress
          sx={{
            display: 'block',
            margin: cancelDowngrade ? '11px auto' : '103px auto',
          }}
        />
        <Hider show={cancelDowngrade}>
          <>
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
              予定しているダウングレードを取り消しますか？
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: '10px',
              }}
            >
              <Button
                size="small"
                color="error"
                sx={{ color: 'inherit', mr: 2 }}
                onClick={() => {
                  setOpenChangePlan(false)
                }}
              >
                キャンセル
              </Button>
              <Button
                variant="outlined"
                size="small"
                color="error"
                onClick={() => {
                  downgrade('', false)
                }}
              >
                確定
              </Button>
            </Box>
          </>
          <>
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
              ダウングレード
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
              {new Date(
                gStripe.subscription.current_period_end * 1000,
              ).getMonth() + 1}
              月
              {new Date(
                gStripe.subscription.current_period_end * 1000,
              ).getDate()}
              日に
              {planToBe === 'FREE' ? 'FREE' : 'PRO'}プランに変更します
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: '10px',
              }}
            >
              <Button
                size="small"
                color="error"
                sx={{ mr: 2, color: 'black' }}
                onClick={() => {
                  setOpenChangePlan(false)
                }}
              >
                キャンセル
              </Button>
              <Button
                variant="outlined"
                size="small"
                color="error"
                onClick={() => {
                  if (planToBe === 'FREE') {
                    downgrade('price_1LkMnTG8znjDYwn9HXn1ytpU', true)
                  } else {
                    downgrade('price_1LkMp0G8znjDYwn9iHDBddLn', true)
                  }
                }}
              >
                確定
              </Button>
            </Box>
          </>
        </Hider>
      </Hider>
    </Box>
  )
}
