import React from 'react'
import { Box, Typography, Button, CircularProgress } from '@mui/material'
import Hider from '../utils/Hider'

export default function UpgradePlan({
  planToBe,
  setOpenChangePlan,
  upgrade,
  changePlanPending,
  setUpgradeBox,
  setChangePlanPending,
}) {
  return (
    <Box>
      <Hider show={changePlanPending}>
        <CircularProgress sx={{ display: 'block', m: '12px auto' }} />
        <>
          <Typography>
            本当に
            <span style={{ display: 'inline-block', fontWeight: 'bold' }}>
              {planToBe}
            </span>
            プランにアップグレードしますか？
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: '10px',
            }}
          >
            <Button
              size="small"
              sx={{ color: 'inherit', mr: 2 }}
              onClick={() => {
                setOpenChangePlan(false)
                setUpgradeBox(false)
              }}
            >
              キャンセル
            </Button>
            <Button
              disableElevation
              variant="contained"
              size="small"
              sx={{ color: 'white' }}
              onClick={() => {
                setChangePlanPending(true)
                // 送出升級方案
                if (planToBe === 'PRO') {
                  upgrade('price_1LkMp0G8znjDYwn9iHDBddLn')
                } else {
                  upgrade('price_1LkMqCG8znjDYwn9B49wB6Vr')
                }
              }}
            >
              確定
            </Button>
          </Box>
        </>
      </Hider>
    </Box>
  )
}
