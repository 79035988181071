import React, { useState, useContext, useEffect } from 'react'
import { API } from 'aws-amplify'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import Hider from '../utils/Hider'
import { Box, IconButton, TextField } from '@mui/material'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'

export default function EditCompanyName({ companyName, setCompanyName }) {
  const [edit, setEdit] = useState(false)
  const [isPending, setIsPending] = useState(false)
  const { gStripe, gAuth } = useContext(GlobalStateContext)
  useEffect(() => {
    if (gStripe.customer.id) {
      setEdit(false)
    }
  }, [gStripe.customer.id])

  return (
    <Hider show={edit}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          variant="outlined"
          size="small"
          defaultValue={companyName}
          disabled={isPending}
          onChange={(e) => {
            setCompanyName(e.target.value)
          }}
        />
        <IconButton
          size="small"
          onClick={() => {
            setIsPending(true)
            API.patch('Attendance', `/info`, {
              body: {
                companyName,
                companyID: gStripe.customer.id,
              },
            })
              .then((res) => {
                localStorage.setItem('currAccountName', companyName)
                gStripe.getStripeInfo(localStorage.getItem('currLogin'))
              })
              .then(() => {
                setCompanyName(gStripe.customer.name)
                setIsPending(false)
                setEdit(false)
                gAuth.getCompanies()
              })
              .catch((err) => console.log(err))
          }}
        >
          <CheckCircleOutlineRoundedIcon fontSize="inherit" />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => {
            setCompanyName(gStripe.customer.name)
            setEdit(false)
          }}
        >
          <CancelOutlinedIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <Box>
        {typeof window !== 'undefined'
          ? localStorage.getItem('currAccountName')
          : null}
        <IconButton
          size="small"
          onClick={() => {
            setEdit(true)
          }}
        >
          <EditRoundedIcon sx={{ color: 'primary.main', fontSize: '14px' }} />
        </IconButton>
      </Box>
    </Hider>
  )
}
