import React, { useEffect, useState, useContext } from 'react'
import { API } from 'aws-amplify'
import Layout from '../layouts'
import Hider from '../components/utils/Hider'
import { GlobalStateContext } from '../context/GlobalContextProvider'
import EditCompanyName from '../components/setting/editCompanyName'
import Counter from '../components/counter'
import CreditCardList from '../components/setting/creditCardList'
import UpgradePlan from '../components/setting/upgradePlan'
import DowngradePlan from '../components/setting/downgradePlan'
import { navigate } from 'gatsby'
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Box,
  Button,
  CircularProgress,
  Modal,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Stack,
} from '@mui/material'
import Divider from '@mui/material/Divider'
import AddCardIcon from '@mui/icons-material/AddCard'
import StopIcon from '@mui/icons-material/Stop'
import PermIdentityTwoToneIcon from '@mui/icons-material/PermIdentityTwoTone'
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone'
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone'
import AddCard from '../components/setting/addCard'
import CloseIcon from '@mui/icons-material/Close'

export default function SettingsNew({ location }) {
  const { gStripe, gAuth, gCreditCard, gLoginEmployee } = useContext(
    GlobalStateContext,
  )
  const [companyName, setCompanyName] = useState(gStripe.customer.name)
  const [upgradeBox, setUpgradeBox] = useState(false)
  const [usagePending, setUsagePending] = useState(false)
  // const [deletePending, setDeletePending] = useState(false)
  const [changePlanPending, setChangePlanPending] = useState(false)
  const [cancelDowngrade, setCancelDowngrade] = useState(false)
  const [openCardModal, setOpenCardModal] = useState(false)
  const [openChangePlan, setOpenChangePlan] = useState(false)
  const [currentPriceId, setCurrentPriceId] = useState(
    'price_1LkMnTG8znjDYwn9HXn1ytpU',
  )
  const [editUsage, setEditUsage] = useState(false)
  const [planToBe, setPlanToBe] = useState(undefined)
  const [downgradePlan, setDowngradePlan] = useState('')
  const [defaultValue, setDefaultvalue] = useState('')
  // const [charge, setCharge] = useState([])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '580px',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    padding: 4,
  }

  const title = {
    m: '8px 5px 8px 0px',
    width: '200px',
    fontWeight: 'bold',
  }

  const infoItem = {
    display: 'flex',
    alignItems: 'center',
  }

  const plan = (priceId) => {
    switch (priceId) {
      case 'price_1LkMnTG8znjDYwn9HXn1ytpU':
        return { plan: 'FREE', limit: 3 }
      case 'price_1LkMp0G8znjDYwn9iHDBddLn':
        return { plan: 'PRO', limit: 5 }
      case 'price_1LkMqCG8znjDYwn9B49wB6Vr':
        return { plan: 'BUSINESS', limit: Infinity }
      default:
        return { plan: '', limit: '' }
    }
  }

  const planContent = {
    free: [
      '登録可能ユーザー 3人',
      '登録可能デバイス 1台',
      '各デバイス履歴管理ページ閲覧可能（全体履歴管理ページ閲覧不可）',
      'ワンタイムゲストキー発行可能 （トライアル）',
    ],
    pro: [
      '登録可能ユーザー 5人',
      '登録可能デバイス 2台',
      '各デバイス、全体履歴管理ページ閲覧可能',
      'ワンタイムゲストキー発行可能（トライアル）',
    ],
    biz: [
      '登録可能ユーザー 無制限',
      '登録可能デバイス 無制限',
      '各デバイス、全体履歴管理ページ閲覧可能',
      'ワンタイムゲストキー発行可能',
    ],
  }

  const upgrade = (price) => {
    API.post('Attendance', `/upgrade`, {
      body: {
        subId: gStripe.customer.subscriptions.data[0].id,
        paymentID: defaultValue,
        price,
        companyID: gStripe.customer.id,
      },
    })
      .then(() => {
        gStripe.getStripeInfo(gStripe.customer.id)
        gStripe.getInvoiceComing(gStripe.customer.id)
        gAuth.getCompanies()
      })
      .then(async () => {
        setOpenChangePlan(false)
        setChangePlanPending(false)
        setUpgradeBox(false)
      })
      .catch((err) => console.log(err))
  }

  const downgrade = (downgradePlan, cancelRenew) => {
    setChangePlanPending(true)
    API.post('Attendance', `/downgrade`, {
      body: {
        subscriptionID: gStripe.subscription.id,
        downgradePlan,
        cancelRenew,
      },
    })
      .then(async (res) => {
        setDowngradePlan(downgradePlan)
      })
      .then(() => {
        setOpenChangePlan(false)
        setChangePlanPending(false)
        setCancelDowngrade(false)
      })
      .catch((err) => console.log(err))
  }

  // =====測試刪除資料用
  const deleteCompany = () => {
    // setDeletePending(true)
    API.del('Attendance', `/`, {
      body: {
        companyID: gStripe.customer.id,
      },
    })
      .then(async (res) => {
        const companiesList = gAuth.companiesList.filter(
          (row) => row.companyID !== gStripe.customer.id,
        )
        gAuth.setCompaniesList(companiesList)
        // setDeletePending(false)
        if (companiesList.length === 0) {
          navigate('/login')
        } else {
          gStripe.getStripeInfo(companiesList[0].companyID)
          navigate('/')
        }
      })
      .catch((err) => console.log(err))
  }
  // ================

  useEffect(() => {
    if (gStripe.customer.name) {
      setCompanyName(gStripe.customer.name)
      setPlanToBe(undefined)
    }
  }, [gStripe.customer.name])

  useEffect(() => {
    if (gStripe.subscription.items.data[0].plan.id) {
      setCurrentPriceId(gStripe.subscription.items.data[0].plan.id)
    }
  }, [gStripe.subscription])

  useEffect(() => {
    if (gStripe.subscription.metadata.downgradePlan) {
      setDowngradePlan(gStripe.subscription.metadata.downgradePlan)
    }
  }, [gStripe.subscription.metadata.downgradePlan])

  useEffect(() => {
    if (gStripe.customer.id) {
      gCreditCard.getCreditCard(gStripe.customer.id) // 拿到卡片資訊
      gStripe.setCount(0)
      gStripe.getInvoiceComing(gStripe.customer.id)
      setEditUsage(false)
    }
    if (gStripe.subscription.id) {
      gStripe.getUsage(gStripe.subscription.id)
    }
  }, [gStripe.customer.id])

  useEffect(() => {
    if (gCreditCard.creditCardList) {
      // 拿到卡片資訊
      setDefaultvalue(gStripe.customer.invoice_settings.default_payment_method)
      if (
        !gStripe.customer.invoice_settings.default_payment_method &&
        gCreditCard.creditCardList.length === 1
      ) {
        API.post('Attendance', `/payment_method`, {
          body: {
            customerId: gStripe.customer.id,
            defaultPaymentMethod: gCreditCard.creditCardList[0].id,
          },
        })
          .then(() => {
            gStripe.setCustomer({
              ...gStripe.customer,
              invoice_settings: {
                default_payment_method: gCreditCard.creditCardList[0].id,
              },
            })
            setDefaultvalue(gCreditCard.creditCardList[0].id)
          })
          .catch((err) => console.log(err))
      }
    }
  }, [gCreditCard.creditCardList])

  return (
    <Layout location={location}>
      {gLoginEmployee.loginInfo.tag && (
        <Card>
          <CardHeader
            title={
              <Typography variant="h2" sx={{ ml: '9px' }}>
                設定
              </Typography>
            }
          />
          <CardContent
            sx={{
              mt: 2,
              p: '0px',
              // border: '1px solid red',
            }}
          >
            <Card>
              <CardHeader
                title={
                  <Typography
                    variant="h3"
                    sx={{
                      color: '#8a8994',
                      display: 'inline-block',
                    }}
                  >
                    会社情報
                  </Typography>
                }
              />
              <Divider sx={{ mt: 1 }} />
              <CardContent>
                <Hider show={gLoginEmployee.loginInfo.tag.includes('オーナー')}>
                  <Box sx={{ ...infoItem }}>
                    <Typography sx={{ ...title }}>会社名</Typography>
                    <EditCompanyName
                      companyName={companyName}
                      setCompanyName={setCompanyName}
                    />
                  </Box>
                </Hider>
                <Box className="currentPlan" sx={{ ...infoItem }}>
                  <Typography sx={{ ...title }}>
                    現在ご契約中のプラン
                  </Typography>
                  <Typography>
                    {plan(currentPriceId).plan}
                    {downgradePlan === ''
                      ? ''
                      : `　※${
                          new Date(
                            gStripe.subscription.current_period_end * 1000,
                          ).getMonth() + 1
                        }月${new Date(
                          gStripe.subscription.current_period_end * 1000,
                        ).getDate()}日に${
                          plan(downgradePlan).plan
                        }プランに変更します`}
                  </Typography>
                </Box>
                <Box className="planUsersLimit" sx={{ ...infoItem }}>
                  <Typography sx={{ ...title }}>
                    登録可能ユーザー数上限
                  </Typography>
                  <Typography
                    sx={{ display: usagePending ? 'none' : 'block', mr: 1 }}
                  >
                    {plan(currentPriceId).plan === 'FREE'
                      ? '3'
                      : gStripe.usage + gStripe.count}
                    人
                  </Typography>
                  <Hider show={usagePending}>
                    <CircularProgress size="20px" sx={{ ml: '5px' }} />
                    <Hider show={editUsage}>
                      <Counter
                        priceID={currentPriceId}
                        location={location}
                        setEditUsage={setEditUsage}
                        setUsagePending={setUsagePending}
                      />
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setEditUsage(!editUsage)
                        }}
                        sx={{
                          display:
                            gStripe.subscription.status === 'trialing' ||
                            !gLoginEmployee.loginInfo.tag.includes(
                              'オーナー',
                            ) ||
                            plan(currentPriceId).plan === 'FREE' ||
                            (plan(currentPriceId).plan === 'PRO' &&
                              gStripe.usage + gStripe.count >= 5)
                              ? 'none'
                              : 'block',
                        }}
                      >
                        追加購入
                      </Button>
                    </Hider>
                  </Hider>
                </Box>
                <Box className="planDuration" sx={{ ...infoItem }}>
                  <Typography sx={{ ...title }}>次のお支払い日</Typography>
                  <Typography>
                    {new Date(
                      gStripe.subscription.current_period_end * 1000,
                    ).toLocaleString('ja-JP', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </Typography>
                </Box>
                <Box className="comingInvoice" sx={{ ...infoItem }}>
                  <Typography className="comingInvoice" sx={{ ...title }}>
                    次回お支払い金額
                  </Typography>
                  <Box sx={{ display: 'flex' }}>
                    <Typography>
                      {new Intl.NumberFormat('ja-JP', {
                        style: 'currency',
                        currency: 'JPY',
                      }).format(gStripe.invoiceComing.totalExcludingTax)}
                    </Typography>
                    <Typography sx={{ ml: '5px', color: 'info.light' }}>
                      (税込
                      {new Intl.NumberFormat('ja-JP', {
                        style: 'currency',
                        currency: 'JPY',
                      }).format(gStripe.invoiceComing.total)}
                      )
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
            {/* 信用卡清單 */}
            <Hider show={gLoginEmployee.loginInfo.tag.includes('オーナー')}>
              <Card
              // sx={{ border: '1px solid #68c6c8' }}
              >
                <CardHeader
                  title={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        variant="h3"
                        sx={{
                          mr: '10px',
                          color: '#8a8994',
                          display: 'inline-block',
                        }}
                      >
                        お支払い方法
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setPlanToBe(undefined)
                          setOpenCardModal(true)
                        }}
                      >
                        <AddCardIcon fontSize="inherit" />
                      </IconButton>
                    </Box>
                  }
                />
                <Divider sx={{ mt: 1 }} />
                <CardContent>
                  <CreditCardList />
                </CardContent>
              </Card>
            </Hider>

            {/* plan區塊 */}
            <Hider show={gLoginEmployee.loginInfo.tag.includes('オーナー')}>
              <Card>
                <CardHeader
                  title={
                    <Typography
                      variant="h3"
                      sx={{
                        color: '#8a8994',
                        display: 'inline-block',
                      }}
                    >
                      プラン
                    </Typography>
                  }
                />
                <Divider sx={{ mt: 1 }} />
                <CardContent sx={{ pt: '20px' }}>
                  <Stack direction="row" spacing={2}>
                    <Card
                      sx={{
                        flex: '1 1 0',
                        width: 0,
                        minHeight: '470px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        borderRadius: 1,
                        boxShadow: 2,
                        p: 4,
                        border: '2px solid',
                        borderColor:
                          plan(currentPriceId).plan === 'FREE'
                            ? 'primary.main'
                            : 'transparent',
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            height: '80px',
                          }}
                        >
                          <Box>
                            <Typography
                              variant="h2"
                              sx={{ fontWeight: 'bold', width: '100%' }}
                            >
                              FREE
                            </Typography>
                            <Typography>個人利用やお試しに</Typography>
                          </Box>
                          <PermIdentityTwoToneIcon
                            sx={{
                              display: 'block',
                              fontSize: 60,
                            }}
                          />
                        </Box>
                        <Typography>
                          一人￥
                          <span
                            style={{
                              fontSize: '50px',
                              fontWeight: 'bold',
                            }}
                          >
                            0
                          </span>{' '}
                          / 月
                        </Typography>
                        <List disablePadding sx={{ p: '8px 0px 0px 0px' }}>
                          {planContent.free.map((row) => (
                            <ListItem
                              alignItems="flex-start"
                              sx={{ p: 'unset' }}
                            >
                              <ListItemIcon
                                sx={{
                                  color: 'unset',
                                  minWidth: 'unset',
                                  mt: '6px',
                                }}
                              >
                                <StopIcon sx={{ fontSize: '12px', mr: 1 }} />
                              </ListItemIcon>
                              <ListItemText sx={{ pl: 'unset', m: 'unset' }}>
                                {row}
                              </ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                      <Button
                        disableElevation
                        disabled={
                          plan(currentPriceId).plan === 'FREE' ||
                          plan(downgradePlan).plan === 'PRO'
                            ? true
                            : false
                        }
                        display="flexEnd"
                        variant="outlined"
                        fullWidth
                        sx={{ marginBottom: '0px' }}
                        onClick={() => {
                          setOpenChangePlan(true)
                          if (downgradePlan !== '') {
                            // 取消降級
                            setCancelDowngrade(true)
                          } else {
                            // 降級為FREE方案
                            setPlanToBe('FREE')
                            setCancelDowngrade(false)
                          }
                        }}
                      >
                        {plan(currentPriceId).plan === 'FREE'
                          ? '現在のプラン'
                          : plan(downgradePlan).plan === 'FREE'
                          ? 'ダウングレードを取り消す'
                          : 'FREEプランにダウングレード'}
                      </Button>
                    </Card>
                    <Card
                      sx={{
                        flex: '1 1 0',
                        width: 0,
                        minHeight: '470px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        borderRadius: 1,
                        boxShadow: 2,
                        p: 4,
                        border: '2px solid',
                        borderColor:
                          plan(currentPriceId).plan === 'PRO'
                            ? 'primary.main'
                            : 'transparent',
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            height: '80px',
                          }}
                        >
                          <Box>
                            <Typography
                              variant="h2"
                              sx={{ fontWeight: 'bold', width: '100%' }}
                            >
                              PRO
                            </Typography>
                            <Typography>少人数での利用に</Typography>
                          </Box>
                          <GroupTwoToneIcon
                            sx={{
                              display: 'block',
                              fontSize: 60,
                            }}
                          />
                        </Box>
                        <Typography>
                          一人￥
                          <span
                            style={{
                              fontSize: '50px',
                              fontWeight: 'bold',
                            }}
                          >
                            99{' '}
                          </span>
                          / 月
                        </Typography>
                        <List disablePadding sx={{ p: '8px 0px 0px 0px' }}>
                          {planContent.pro.map((row) => (
                            <ListItem
                              alignItems="flex-start"
                              sx={{ p: 'unset' }}
                            >
                              <ListItemIcon
                                sx={{
                                  color: 'unset',
                                  minWidth: 'unset',
                                  mt: '6px',
                                }}
                              >
                                <StopIcon sx={{ fontSize: '12px', mr: 1 }} />
                              </ListItemIcon>
                              <ListItemText sx={{ pl: 'unset', m: 'unset' }}>
                                {row}
                              </ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                      <Hider show={plan(currentPriceId).plan === 'BUSINESS'}>
                        <Button
                          disableElevation
                          disabled={
                            plan(currentPriceId).plan === 'PRO' ||
                            plan(downgradePlan).plan === 'FREE'
                              ? true
                              : false
                          }
                          variant="outlined"
                          fullWidth
                          sx={{ marginBottom: '0px', mt: 2 }}
                          onClick={() => {
                            setOpenChangePlan(true)
                            if (downgradePlan !== '') {
                              // 取消降級
                              setCancelDowngrade(true)
                            } else {
                              // 降級為PRO方案
                              setPlanToBe('PRO')
                            }
                          }}
                        >
                          {plan(currentPriceId).plan === 'PRO'
                            ? '現在のプラン'
                            : plan(downgradePlan).plan === 'PRO'
                            ? 'ダウングレードを取り消す'
                            : 'PROプランにダウングレード'}
                        </Button>
                        <Button
                          disabled={
                            plan(currentPriceId).plan === 'PRO' ? true : false
                          }
                          variant="outlined"
                          fullWidth
                          sx={{ mt: 2 }}
                          onClick={() => {
                            setUpgradeBox(true)
                            setPlanToBe('PRO')
                            if (gCreditCard.creditCardList.length === 0) {
                              setOpenCardModal(true)
                            } else {
                              setOpenChangePlan(true)
                            }
                          }}
                        >
                          PROプランにアップグレード
                        </Button>
                      </Hider>
                    </Card>
                    <Card
                      sx={{
                        flex: '1 1 0',
                        width: 0,
                        minHeight: '470px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        borderRadius: 1,
                        boxShadow: 2,
                        p: 4,
                        border: '2px solid',
                        borderColor:
                          plan(currentPriceId).plan === 'BUSINESS'
                            ? 'primary.main'
                            : 'transparent',
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            height: '80px',
                          }}
                        >
                          <Box>
                            <Typography
                              variant="h2"
                              sx={{ fontWeight: 'bold', width: '100%' }}
                            >
                              BUSINESS
                            </Typography>
                            <Typography>企業など大人数での利用に</Typography>
                          </Box>
                          <BusinessTwoToneIcon
                            sx={{
                              display: 'block',
                              fontSize: 60,
                            }}
                          />
                        </Box>
                        <Typography>
                          一人￥
                          <span
                            style={{
                              fontSize: '50px',
                              fontWeight: 'bold',
                            }}
                          >
                            399{' '}
                          </span>
                          / 月
                        </Typography>
                        <List disablePadding sx={{ p: '8px 0px 0px 0px' }}>
                          {planContent.biz.map((row) => (
                            <ListItem
                              alignItems="flex-start"
                              sx={{ p: 'unset' }}
                            >
                              <ListItemIcon
                                sx={{
                                  color: 'unset',
                                  minWidth: 'unset',
                                  mt: '6px',
                                }}
                              >
                                <StopIcon sx={{ fontSize: '12px', mr: 1 }} />
                              </ListItemIcon>
                              <ListItemText sx={{ pl: 'unset', m: 'unset' }}>
                                {row}
                              </ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                      <Box>
                        <Button
                          disabled={
                            plan(currentPriceId).plan === 'BUSINESS' ||
                            plan(downgradePlan).plan === 'FREE'
                              ? true
                              : false
                          }
                          variant="outlined"
                          fullWidth
                          sx={{ marginBottom: '0px', p: '5px 8px 5px 8px' }}
                          onClick={() => {
                            setUpgradeBox(true)
                            setPlanToBe('BUSINESS')
                            if (gCreditCard.creditCardList.length === 0) {
                              setOpenCardModal(true)
                            } else {
                              setOpenChangePlan(true)
                            }
                          }}
                        >
                          {plan(currentPriceId).plan === 'BUSINESS'
                            ? '現在のプラン'
                            : 'BUSINESSプランにアップグレード'}
                        </Button>
                      </Box>
                    </Card>
                  </Stack>
                  <Typography sx={{ mt: '5px' }}>
                    ※プランの表示価格はすべて税抜き価格です。
                  </Typography>
                </CardContent>
              </Card>
            </Hider>

            {/* 刪除公司Button */}
            {/* <Button
            sx={{ mt: '20px', ml: '18px' }}
            variant="outlined"
            size="small"
            color="error"
            onClick={() => {
              deleteCompany()
              // setOpenDelModal(true)
            }}
          >
            測試用刪除此stripe帳戶和所有資料
          </Button> */}
            {/* 更改方案Modal */}
            <Modal
              open={openChangePlan}
              onClose={() => {
                setOpenChangePlan(false)
              }}
            >
              <Box sx={{ ...style }}>
                <Hider show={upgradeBox}>
                  <UpgradePlan
                    planToBe={planToBe}
                    setOpenChangePlan={setOpenChangePlan}
                    upgrade={upgrade}
                    changePlanPending={changePlanPending}
                    setUpgradeBox={setUpgradeBox}
                    setChangePlanPending={setChangePlanPending}
                  />
                  <DowngradePlan
                    planToBe={planToBe}
                    downgrade={downgrade}
                    setOpenChangePlan={setOpenChangePlan}
                    changePlanPending={changePlanPending}
                    cancelDowngrade={cancelDowngrade}
                  />
                </Hider>
              </Box>
            </Modal>
            {/* 信用卡Modal */}
            <Modal
              open={openCardModal}
              onClose={() => {
                setOpenCardModal(false)
                setPlanToBe(undefined)
              }}
            >
              <Box sx={{ ...style }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h3">お支払い方法の追加</Typography>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setOpenCardModal(false)
                      setPlanToBe(undefined)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </Box>
                <AddCard
                  setOpenCardModal={setOpenCardModal}
                  customerID={gStripe.customer.id}
                  upgrade={upgrade}
                  priceID={
                    planToBe === 'PRO'
                      ? 'price_1LkMp0G8znjDYwn9iHDBddLn'
                      : 'price_1LkMqCG8znjDYwn9B49wB6Vr'
                  }
                  planToBe={planToBe}
                />
              </Box>
            </Modal>
          </CardContent>
        </Card>
      )}
    </Layout>
  )
}
